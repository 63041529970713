import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import vuetify from './plugins/vuetify'
import Vue3Marquee from 'vue3-marquee'
// import { loadFonts } from './plugins/webfontloader'

const app=createApp(App)

axios.defaults.baseURL = 'https://api.trinity4education.com/';
app.config.globalProperties.baseURL = "https://api.trinity4education.com/"
app.config.globalProperties.mediaURL = "https://api.trinity4education.com/file/get?key="
app.config.globalProperties.mediaURLnew = "https://api.trinity4education.com/wp?key=" 


// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.52.121:4000';
// app.config.globalProperties.baseURL = "http://192.168.52.121:4000";
// app.config.globalProperties.mediaURL = "http://192.168.52.121:4000/file/get/"
// app.config.globalProperties.mediaURLnew = "http://192.168.52.121:4000/wp?key="



// loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Vue3Marquee)
  .mount('#app')

<template>
  <div>
    <v-row justify="center">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-row justify="center">
          <v-col lg="8" class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-col>
          <v-col lg="1">
            <v-btn variant="small" :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10" lg="11" class="text-center">
        <!-- <v-card rounded="10" class="custom-card pa-0 pa-sm-3 pa-md-3 pa-lg-3">
          <v-card-title class="reg">Registration <span style="color: rgba(248, 141, 47, 1) ;">Form</span></v-card-title>
          <v-card-text>
            <form @submit="submitForm">
              <v-text-field
                label="Name"
                variant="outlined"
                required
                v-model="name"
              ></v-text-field>

              <v-text-field
                :counter="10"
                label="Phone Number"
                variant="outlined"
                required
                v-model="phoneNumber"
                :rules="phoneRules"
              ></v-text-field>

              <v-text-field
                label="E-mail"
                variant="outlined"
                required
                v-model="email"
                :rules="emailRules"
              ></v-text-field>

              <v-select label="Select Your Course" :items="courses" v-model="selectedCourse" :rules="courseRules" variant="outlined" required></v-select>

              <v-select v-if="selectedCourse" label="Select Sub-Course" :items="filteredSubCourses" :rules="courseRules" variant="outlined" required></v-select>


              <v-btn type="submit" color="blue">Submit</v-btn>&nbsp;&nbsp;&nbsp;
              <v-btn @click="clearForm" color="green">Clear</v-btn>
            </form>
          </v-card-text>
        </v-card> -->
        <v-card rounded="10" class="custom-card pa-0 pa-sm-3 pa-md-3 pa-lg-3">
          <v-row class="pl-4 pr-4 pt-3">
            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 pt-1 text-left">
              <v-row justify="start">
                <v-col lg="12">
                  <span>Name</span>
                </v-col>
              </v-row>
              
              <v-text-field
                variant="outlined"
                
                style="background-color: white"
                placeholder="Enter Name"
                clearable
                density="compact"
                type="text"
                v-model="name"
                :rules="nameRules"
                
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 pt-1 text-left">
              <span>E-Mail Address</span>
              <v-text-field
                variant="outlined"
                
                style="background-color: white"
                placeholder="johndoe@gmail.com"
                clearable
                density="compact"
                type="email"
                v-model="email"
                :rules="emailRules"
                
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 pt-1 text-left">
              <span>Phone No</span>
              <v-text-field
                variant="outlined"
                class="inputPrice"
                style="background-color: white"
                placeholder="Phone No"
                clearable
                density="compact"
                type="number"
                v-model="phone"
                :rules="phoneRules"
                
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 pt-1  text-left">
              <span>Qualification</span>
              <v-select
                placeholder="Select Your Qualification"
                :items="qualifications"
                variant="outlined"
                density="compact"
                v-model="quali"
                :rules="qualiRules"
                
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 pt-1  text-left">
              <span>Intrested Course</span>
              <v-select
                placeholder="Select Your Course"
                :items="courses"
                density="compact"
                v-model="course"
                variant="outlined"
                :rules="courseRules"
                
              ></v-select>

              <!-- Update the template to use the new computed property name -->
              <v-select
                v-if="selectedCourse"
                placeholder="Select Sub-Course"
                :items="filteredSubCourses"
                variant="outlined"
                density="compact"
                
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="pb-0 pt-1  text-left">
              <span>Institute</span>
              <v-select
                placeholder="Select Your Institute"
                :items="institutes"
                variant="outlined"
                density="compact"
                v-model="institute"
                :rules="institueRules"
                
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="9" sm="8" md="8" lg="8">
              <v-card-actions class="pa-3 pt-0">
                <v-btn
                  style="
                    color: #ffffff;
                    background-color: rgba(58, 129, 230, 1);
                  "
                  size="large"
                  type="submit"
                  @click="validation()"
                  >&nbsp; SEND &nbsp;</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      qualifications: ["BTECH", "CA", "BA", "BCOM", "BBA", "MBA"],
    courses: ["CIFA", "CGA","SAP HANA","ADVANCE EXCEL","GST COURSE","TALLY","CMA","CA","AI","LanguageCourses","ComputerCourses"],
    subCourses: [],
    selectedCourse: null,
    institutes: ["KOTTAYAM", "CHANGANACHERRY"],
    name:null,
    email:null,
    phone: null,
    quali:null,
    course:null,
    institute:null,
    Qualification: null,
    Course: null,
    errorMessages: "",
    formHasErrors: false,
    showSnackBar:false,
    };
  },
  computed: {
    filteredSubCourses() {
      const subCoursesMap = {
        CIFA: ["CIFA Basic", "CIFA Basic Plus", "CIFA Pro"],
        CGA: ["CGA Basic", "CGA Basic Plus", "CGA Pro", "CGA Basic Plus"],
        SAPHANA: ["FICO", "SD", "MM", "PP"],
        ADVANCEEXCEL: [
          "ADVANCED Basic",
          "ADVANCED Basic Plus",
          "ADVANCED Pro",
          "ADVANCED Basic",
        ],
        GSTCOURSE: ["GST Basic", "GST Basic Plus", "GST Pro"],
        TALLY: [
          "TALLY Basic",
          "TALLY Basic Plus",
          "TALLY Pro",
          "TALLY Basic Plus",
        ],
        CMA: ["CMA-USA"],
        CA: ["CA-FOUNDATION COACHING", "CGA Sub2"],
        AI: ["ROBOTICS", "MACHINE LEARNING", "CODING"],
        LanguageCourses: ["IELTS", "OET", "SPOKEN ENGLISH", "GERMAN", "FRENCH"],
        ComputerCourses: [
          "MSOFFICE Suite",
          "PYTHON",
          "Django using Python",
          "HTML",
          "CSS",
          "C++",
          "JAVA",
          "PHOTOSHOP",
          "Diploma in Computer Application (DCA)",
          "Diploma in Office Automation Course (DOA)",
          "Digital Marketing",
        ],
      };

      return this.selectedCourse
        ? subCoursesMap[this.selectedCourse] || []
        : [];
    },
    nameRules() {
      return [(v) => !!v || "Name is required"];
    },
    phoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    qualiRules() {
      return [(v) => !!v || "Qualification is required"];
    },
    courseRules() {
      return [(v) => !!v || "Course is required"];
    },
    institueRules() {
      return [(v) => !!v || "Institution is required"];
    },
  },
  methods: {
    validation() {
      if (!this.name) {
        this.msg = "Please Provide Name";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please Provide E-mail";
        this.showSnackBar = true;        return;
      } else if (!this.phone) {
        this.msg = "Please Provide Phone No";
        this.showSnackBar = true;        return;
      } else if (!this.quali) {
        this.msg = "Please Provide Qualification";
        this.showSnackBar = true;        return;
      } else if (!this.course) {
        this.msg = "Please Provide course";
        this.showSnackBar = true;        return;
      } else if (!this.institute) {
        this.msg = "Please Provide institute";
        this.showSnackBar = true;        return;
      } else this.send();
    },

    send(){
      axios({
        method: "POST",
        url: "/contactus/form",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          quali: this.quali,
          course:this.course,
          institute:this.institute,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            
            this.msg = response.data.msg;
            this.showSnackBar = true;
            
          } else {
            // this.memberdialog = true;

            this.msg = response.data.msg;
           
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    clearForm() {
      this.name = "";
      this.phoneNumber = "";
      this.email = "";
      this.selectedCourse = null;
    },
  },
};
</script>

<style>
.reg {
  font-family: archireg;
  font-size: 20px;
}

.button {
  font-family: archireg;
}
.custom-card {
  border-radius: 20px; /* Adjust the value for desired curvature */
}
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
